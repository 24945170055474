.map{

}

.leaflet-control-attribution{
    font-size: 8px !important;
}

.leaflet-control-attribution a{
    font-size: 8px !important;
}

.leaflet-control-attribution span{
    font-size: 8px !important;   
}