.event-page-items-div{
  overflow-x: hidden;
}

.event-page-image-div{
    width: 105%;
    height: calc(100vh - 80px);
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    position: relative;
}

.event-map{
  width: 100%;
  height: 150px;
  border-radius: 12px;
  margin: 0;
}

.event-map-border-radius{
  width: 45%;
  max-width: 150px;
  height: 150px;
  border-radius: 12px;
  margin: 5%;
}

.location-icon{
  width: 7%;
  margin-top: 5%;
  margin-right: 5%;
  display: inline-table !important;
  height: 30px;
}

.event-location-and-date{
  width: 47%;
  margin: 3%;
  display: flex;
}
.event-location-name span{
  font-size: 13px;
  display: block;
}

.event-location-name-span{
  width: 85%;
  display: inline !important;
  font-size: 18px !important;
  text-align: left !important;
}

.date-of-event{
  display: flex;
  text-align: center;
  width: 100%;
  margin: 0px auto;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}


.event-location-name{
  width: 100%;
  padding: 20px;
  display: flex;
}

.event-location-name p{
  display: flex;
  align-items: flex-start;
  width: 100%;
  text-align: center;
  font-weight: 200;
}

.location-name-map-link{
  width: 80%;
  text-decoration: none;
  text-align: left;
  font-size: 15px;
}

.event-map-div{
  width: 80%;
  max-width: 500px;
  display: flex;
  margin: 10px auto;
  height: max-content;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: var(--event-page-box-shadow);
  align-items: center;
  justify-content: space-between;
  padding: 2px;
}

.event-page-div{
    width: 100%;
    margin: 0;
    padding: 0;
}

.image-gradient-bottom::after {
    display: block;
    position: absolute;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0) 0%, rgba(0, 0, 0, 0.93) 62%);
    top: 20vh;
    height: calc(80vh - 80px);
    width: 100%;
    content: '';
    z-index: 10;
    margin: 0px;
    padding: 0px;
  }


  .image-gradient-top::after {
    display: block;
    position: absolute;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.76) 100%);
    top: 0%;
    height: 50%;
    width: 100%;
    content: '';
    z-index: 1;
    margin: 0;
    padding: 0;
  }

  .event-page-title{
    font-size: 2em;
    margin: 10px auto;
    font-weight: bold;
    z-index: 30;
    color: white;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .event-page-title-div{
    position: absolute;
    bottom: 2%;
    z-index: 30;
    width: 75%;
    left: 12.5%;
  }

  @media only screen and (min-width: 900px) {
      .event-page-image-div{
        width: 100%;
        height: calc(100vh - 80px);
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
        padding: 0;
      }
        .event-page-title-div{
            position: absolute;
            top: 10%;
            margin: 20px;
            left: 10px
            
        }
        .image-gradient-bottom::after{
          background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.76) 100%);
        }
        .image-gradient-top::after{
          background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.76) 100%);
            height: 25%;
            z-index: 1;
        }
        .ticket-buying-scroll-buttons{
            position: absolute;
            bottom: 10px;
            height: max-content;
        }
        .ticket-buying-scroll-buttons{
          top: 90% ! important;
        }
        .user-side-media{
          width: 100% !important;
        }
        .user-side-media-elements{
          width : max-content !important;
        }
        .event-page-title{
          font-size: 3.0em;
          width: max-content;
          margin: 30px 15px 0px 15px;
          max-width: fit-content;
          text-align: left;
      }
      .date-of-event{
        display: inline;
        position: absolute;
        top: 10px;
        left: -37%;
      }
      .location-name-map-link{
        font-size: 17px !important;
      }
  }

  @media screen and (width < 500px) {
    .event-map{
      width: 100%;
      height: 100px;
      border-radius: 12px;
    }
    
    .event-map-border-radius{
      width: 45%;
      max-width: 100px;
      height: 100px;
      border-radius: 12px;
      margin: 5%;
    } 
  }


.event-page-date{
    margin: 10px;
    margin-top: 0;
    color: white;
    font-size: 16px;
    font-weight: bold;
}
.ticket-buying-scroll-buttons{
    position: absolute;
    top: 100px;
    right: 1%;
    font-weight:bolder;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
    z-index: 30;
    cursor: pointer;
    width: max-content;
    display: flex;
}

.buy-ticket-button:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-3px);
  }

.other-datas{
    height: 100%;
    position: relative;
    bottom: 1rem;
}

.user-side-media{
  display: inline-table;
  margin: 0 10px;
}

.user-side-media-elements{
  width: 80%;
  margin: 10px auto;
}

.event-page-description{
  width: 90%;
  margin: 20px auto;
  text-align: justify;
}

.buy-ticket-scroll-button{
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.buy-ticket-scroll-button span{
  color: white;
}

.ticket-icon-container {
  display: inline-block;
  transition: transform 0.3s;
}

.buy-ticket-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.ticket-buying-scroll-button{
  margin: 5px;
}

.open-map-link-by-event{
  color: #adadad !important;
  text-decoration: underline;
  font-size: 10px;
  font-weight: 100;
}

.open-map-link-by-event i {
  color: #adadad !important;
}

.event-map-titles{
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  margin-left: 5%;
  width: 85%;
}