.overview {
    background-color: #fff;
    border-radius: 4px;
    padding: 25px;
    margin: 20px;
    box-shadow: var(--event-page-box-shadow);
    border-radius: 10px;
}

.name-of-event {
    font-size: 24px;
    margin-bottom: 30px;
}

.full-price {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.buy-ticket-overview-price {
    float: right;
    margin-right: 10px;
}
