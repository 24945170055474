@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700;900&display=swap");
:root{
    --event-page-box-shadow: 0px .3px 2px rgba(0,0,0,0.1);
    --ligther-color: #adadad;
    --primary-blue: #007bff;
}

.MuiAvatarGroup-root {
    justify-content: center;
}

* {
    font-family: "Rubik", sans-serif;
    color: #7d7d7d;
}

h1{
    letter-spacing: 1.5px;
}

h2{
    letter-spacing: .8px;
}
h3{
    letter-spacing: .7px;
}
h4{
    letter-spacing: .6px;
}
span{
    letter-spacing: .5px;
}
label{
    letter-spacing: .4px;
}
div{
    letter-spacing: .5px;
}
p{
    letter-spacing: .5px;
    line-height: 1.5;
    margin: 8px;
}

#root {
    padding-top: 80px;
}
/*html {
    overflow: hidden;
    height: 100%;
}

body {
    height: 100%;
    overflow: auto;
}*/
body {
    padding: 0px;
    margin: 0px;
    background-color: #f2f2f2;
}

.event-image {
    width: 300px;
    border-radius: 5px;
    padding: 0px;
    margin: 0px;
}

#loading img {
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
}

/*load animation*/

@keyframes loadEvents {
    0% {
        opacity: 0;
        position: relative;
        top: -1000px;
    }
    100% {
        opacity: 1;
        position: relative;
        top: 0px;
    }
}

/*details button animation*/

.event-divs:hover .getEventButton {
    opacity: 1;
    cursor: pointer;
    transition: 0.4s;
}

.getEventButton:hover {
    color: rgba(169, 206, 21, 255);
    background-color: white;
    transition: 0.4s;
}

.main {
    width: max-content;
    margin: 10px auto;
}

.mainDiv {
    min-height: 75vh;
}

apple-pay-button {
    --apple-pay-button-width: 150px;
    --apple-pay-button-height: 30px;
    --apple-pay-button-border-radius: 3px;
    --apple-pay-button-padding: 0px 0px;
    --apple-pay-button-box-sizing: border-box;
}

.selected {
    background: rgba(22, 119, 255, 1) !important;
}