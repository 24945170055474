.seat-map-canvas-holder{
    position: relative;
}

.canvas-overlay {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 500px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

.map-container {
    transition: transform 0.3s ease-in-out; /* Adjust duration and easing as needed */
  }
