.seat-legend-conteiner{
    display: flex;
    width: max-content;
    max-width: 500px;
    margin: 20px auto;
    padding: 10px 0 10px 0;
    border-radius: 12px;
    box-shadow: var(--event-page-box-shadow);
    width: 80%;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: white;
}

.legend{
    width: max-content;
    max-width: 34%;
    margin: 10px;
}

.legend-canvas{
    display: block;
    margin: 10px auto;
}

.legend-label{
    text-align: center;
    font-size: 14px;
}