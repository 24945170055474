.file-media-holder{
    width: 70%;
    outline: none;
    overflow: hidden;
    margin: 50px auto;
    height: 70vh;
}

.file-media-holder img{
    width: 100%;
}

.file-media-holder video{
    width: 100%;
}

video::-webkit-media-controls {
    display:none !important;
  }
  
  video::-webkit-media-controls-enclosure {
    display:none !important;
  }
  
  video::-webkit-media-controls-panel {
    display:none !important;
  }
  
  video::-webkit-media-controls-play-button {
    display:none !important;
  }
  
  video::-webkit-media-controls-start-playback-button {
    display:none !important;
  }
  