@media screen and (width >= 1000px) {
    .details-holder {
        display: flex;
        margin: 5px;
    }
    .payingButton {
        float: right;
        display: inline !important;
        margin: 10px !important;
    }
    .details-1st-step {
        max-width: 60%;
    }
    .first-step-details-holder {
        width: 100%;
        display: flex;
    }
    .button-grid {
        display: flex;
        width: 90%;
        justify-content: flex-end;
        margin: 10px auto;
    }
    .details {
        width: 50%;
    }
    .details-1st-step {
        width: 47%;
        margin: 1.25%;
    }
}

.payingButton {
    font-size: 15px;
    margin: 30px;
    padding: 5px;
    outline: none;
    border: none;
    display: block;
    cursor: pointer;
    display: block;
}

.paying-action-buttons {
    width: calc(100% - 20px);
    display: flex;
    justify-content: flex-end;
}

@media screen and (width < 1000px) {
    .details {
        width: 90%;
        margin: 10px auto;
    }
    .button-grid {
        width: max-content;
        margin: 10px auto;
    }
    .paying-action-buttons {
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin: 20px auto;
    }
}

.ticket-buying-main-title{
    margin: 10px;
}

.datas-of-customer label{
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.datas-of-customer h3{
    margin: 15px 0;
    font-size: 18px;
}

.datas-of-customer h2{
    margin: 15px 0;
    font-size: 20px;
}

.accept-term-and-conditions label{
    margin-left: 5px;
}