/* Footer styles */

footer {
  background-color: #f8f9fa;
  padding: 30px 0 10px 0;
  position: relative;
  z-index: 1;
}

footer h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

footer ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

footer li {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 10px;
}

footer li a {
  color: #666;
  text-decoration: none;
}

footer li a:hover {
  color: #333;
}

/* Font Awesome icons */

footer li i {
  margin-right: 10px;
  color: #666;
}
footer .col-md-6{
  width: 200px;
  display: inline-table;
  margin: 30px
}

footer .row {
  display: block;
  width: max-content;
  margin: 10px auto;
}

/* Responsive styles */

@media (max-width: 767px) {
  footer {
    text-align: center;
  }
  
  footer .footer-container {
    max-width: 100%;
    padding: 0 15px;
  }
  
  footer .row {
    display: block;
    width: 80%;
    margin: 10px auto;
  }
  
  footer .col-md-6 {
    margin-bottom: 30px;
  }
}
#copyright{
  font-size: 14px;
}

.footer-bottom{
  width: max-content;
  display: block;
  margin: 20px auto;
  margin-bottom: 2px;
  color: #333;
}

.footer-map{
  margin: 10px auto;
  width: 80%;
  height: 150px
}