.notification-box{
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 9999999999;
  max-width: calc( 100% - 20px );
}

@media screen and (max-width : 1000px){
  .notification-box{
    position: fixed;
    top: 90px;
    left: 10%;
    z-index: 999999;
    width: 70%;
    min-width: 80%;
    height: max-content;
  }
}