* {
    padding: 0;
    margin: 0
}

.wrapper {
    height:70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#eee;
    flex-direction: column;
}

.animated-check {
    height: 10em;
    width: 10em
}

.animated-check path {
    fill: none;
    stroke: white;
    stroke-width: 5px;
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: draw .5s linear forwards;
    animation-delay: .5s;
    stroke-linecap: round;
    opacity: 0;
}


.successfully-buying-title{
    color: #7ac142;
    text-align: center;
    font-size: 10vh;
    opacity: 1;

}

.animated-check circle{
    fill: rgb(121, 193, 66);
}


@keyframes draw {
    0%{
        opacity: 1;
    }
    to {
        stroke-dashoffset: 0;
        opacity: 1;
    }
}