.ticket-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.user-page-ticket{
  max-width: 500px;
  display: block;
  margin: 20px auto;
  width: 80%;
}

/*

.ticket-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.ticket-title {
  font-size: 18px;
}

.ticket-price {
  font-size: 14px;
  color: #888;
}

.ticket-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantity-button {
  border: none;
  background-color: #eee;
  color: #555;
  width: 32px;
  height: 32px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  align-content: center;
  transition: background-color 0.3s ease;
  box-shadow: .5px .5px 3px rgba(0,0,0,0.2);
  font-weight: 100;
  margin: 5px;
}

.quantity-button:hover {
  background-color: #ddd;
}

#quantity_0,
#quantity_1 {
  margin: 0 8px;
  font-size: 16px;
}

.ticket-box{
  margin: 20px;
}

.summend-price-box{
  margin-top: 40px;
  width: 100%;
}

.summend-price{
  font-size: 18px;
  float: right;
}

.summend-price-title{
  font-size: 20px;
}

.tickets-title{
  margin-top: 5px;
  margin-bottom: 30px;
}

.summed-price-box-space{
  height: 50px;
}

.outOfTickets{
  background-color: red;
  color: white;
  padding: 5px;
  text-align: center;
  width: max-content;
  display: block;
  margin: 5px auto;
}*/


.ticket-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: var(--event-page-box-shadow);
  max-width: 100%;
}

.ticket-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
}

.ticket-info {
  display: flex;
  flex-direction: column;
}

.ticket-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.ticket-price {
  font-size: 13px;
  color: var(--ligther-color) !important;
}

.quantity-selector {
  display: flex;
  align-items: center;
}

.quantity-btn {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 15px;
  margin: 0 5px;
  transition: background-color 0.2s ease;
}

.quantity-btn:hover {
  background-color: #0056b3;
}

.ticket-quantity {
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #f7f7f7;
  border: 2px solid #007bff;
  border-radius: 50%;
  transition: background-color 0.2s ease, border-color 0.2s ease, transform 0.2s ease;
}

.ticket-quantity.animate {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  transform: scale(1.1);
  animation: pulse 0.3s ease-in-out;
}

.ticket-quantity-skeleton{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket-sold-out {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #e74c3c;
  color: #fff;
  padding: 3px 8px;
  border-radius: 5px;
  opacity: 1;
  transform: rotateZ(8deg);
  position: relative;
  right: 10px;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.total-price {
  font-size: 17px;
  font-weight: bold;
  margin-top: 20px;
  text-align: right;
  width: 100%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}