.alert-notification {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 200;
}

.event-page-background {
    width: 100%;
    height: 100vh;
    z-index: -1;
    position: fixed;
}

.event-page-div {
    overflow-x: hidden;
}
