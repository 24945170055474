.billing-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
}

.billing-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.billing-field {
    margin-bottom: 15px;
}

.billing-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.billing-value {
    font-size: 16px;
}