.datas-of-customer {
    align-items: center;
    align-content: center;
    margin: 20px;
    padding: 20px;
    box-shadow: var(--event-page-box-shadow);
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    transition: 0.4s;
    border-radius: 10px;
}

#city-div {
    width: 62.5%;
    display: inline-table;
    margin-left: 2.5%;
}

@media screen and (width > 1000px) {
    .datas-of-customer {
        width: 45%;
        display: inline-table;
        margin: 20px;
    }

    .datas-of-customer input[type="text"],
    .datas-of-customer input[type="phone"],
    .datas-of-customer input[type="email"] {
        margin: 4px 0;
        width: 80%;
    }
    #city-div {
        width: 42.5%;
        margin-left: 2.5%;
    }
}

.customer-data-component {
    margin: 40px 0;
}

/*.datas-of-customer input[type="text"],
.datas-of-customer input[type="phone"],
.datas-of-customer input[type = "email"]
{
    width: 100%;
    display: block;
    margin: 4px auto;
    padding: 10px;
    font-size: 17px;
    border: none;
    border-radius: 4px;
    outline: none;
    background-color: #fff;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

/*.datas-of-customer input[type="text"]:focus
.datas-of-customer input[type="phone"]:focus,
.datas-of-customer input[type = "email"]:focus
{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}*/

#postal-code-div {
    width: 35%;
    display: inline-table;
    margin: 0;
}

#postal-code-div input {
    width: 100%;
}

#city-div input {
    width: 100%;
}

.post-code-and-city-div {
    width: 100%;
    margin: 10px auto;
    display: flex;
}

.is-company-option {
    margin-bottom: 20px;
}
