.event-date{
    font-size: 14px;
    margin: 0;
    padding: 10px;
    background: white;
    border-radius: 10px;
    width: 38px;
    height: 38px;
    box-shadow: 0.6px 0.6px 2.5px rgba(0, 0, 0, 0.1);
}

.calendar-text{
    display: inline-block;
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 0px;
    cursor:default;
}

.calendar-month{
    font-size: 13px;
    font-weight: 200;
}

.calendar-date{
    font-weight:700;
    font-size: 16px;
}