@keyframes attention {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.attention-svg {
    width: 100px;
    height: 100px;
    fill: #f0ad4e;
    transform-origin: center;
}

#exclamation-mark {
}
